















































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Manager from '@/models/Manager';
import { managerStoreActions, managerStoreGetter } from '@/store/manager.store';

const ManagerStore = namespace('manager');

@Component({
    components: {
        ManagerTableComponent: () => import(
            /* webpackChunkName: "ManagerTableComponent" */
            '@/components/Managers/ManagersTable.component.vue'),
        EditManagerComponent: () => import(
            /* webpackChunkName: "EditManagerComponent" */
            '@/components/Managers/EditManager.component.vue'),
        ChangeStatusComponent: () => import(
            /* webpackChunkName: "ChangeStatusComponent" */
            '@/components/Managers/EditManagerStatus.component.vue')
    }
})
export default class ManagersView extends Vue {
    @ManagerStore.Action(managerStoreActions.GET)
    public loadAllManagers!: () => Promise<Manager[]>;

    @ManagerStore.Action(managerStoreActions.DELETE)
    public deleteManager!: (id: string) => Promise<void>;

    @ManagerStore.Getter(managerStoreGetter.MANAGERS)
    public managers!: Manager[];

    public searchValue: string = '';
    public showCreationDialog: boolean = false;
    public showDeleteDialog: boolean = false;
    public showStatusDialog: boolean = false;
    private isLoading: boolean = false;
    private selectedManager: Manager | null = null;

    public async created() {
        try {
            this.isLoading = true;
            await this.loadAllManagers();
        } finally {
            this.isLoading = false;
        }
    }

    public async closeDialog(reload: boolean) {
        this.showCreationDialog = false;
        this.showDeleteDialog = false;
        this.showStatusDialog = false;
        this.selectedManager = null;
        if (reload) {
            await this.loadAllManagers();
        }
    }

    public async onEditManager(item: Manager) {
        this.selectedManager = item;
        this.showCreationDialog = true;
    }

    public onDeleteManager(item: Manager) {
        this.selectedManager = item;
        this.showDeleteDialog = true;
    }

    public onChangeStatus(item: Manager) {
        this.selectedManager = item;
        this.showStatusDialog = true;
    }

    public async onDeleteManagerConfirmed() {
        if (!this.selectedManager) return;
        try {
            this.isLoading = true;
            await this.deleteManager(this.selectedManager.id);
            this.showDeleteDialog = false;
            await this.loadAllManagers();
            this.$notifySuccessSimplified('MANAGERS.DELETE.SUCCESS');
        } catch (e) {
            this.$notifyErrorSimplified('MANAGERS.DELETE.ERROR');
        } finally {
            this.isLoading = false;
        }
    }
}
